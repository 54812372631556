<template>

  <div :class="'fI-slow login-page mobile_' + isMobile()">

    <div id="loginWidget" style="display:none;">
      <div class="pulsate loginWidgetText">
        <div>~ <translate>PLEASE LOGIN</translate> ~</div>
      </div>
    </div>

    <LoginWidgets v-if="product_mode === 'pro'" />

    <div style="overflow: hidden;">

      <FarmgodHeader>
        <div class='row' style="top: 10%; position: sticky;">
          <div class='col-sm-12'>
            <h1 id="logo_main" class="teaserLogoMain">
              <Logo v-bind:link="'none'" v-bind:title="'Powered by FarmGod.io'"/>
            </h1>
          </div>
        </div>
      </FarmgodHeader>

      <FormulateForm
        v-model='values'
        :errors='errors'
        @submit="login()"
        #default="{ hasErrors }"
        name="LoginTestForm1"
      >
        <div id="loginForm">
          <div v-if='errors.root' id='loginRootError' class='landingPageInput item-errors alert alert-danger'>
            <translate>Login error:</translate> {{ errors.root }}
          </div>
          <div class="logo_spacer_top"></div>
          <div class="loginFormWrapper">
            <FormulateInput
                class="landingPageInput"
                autocomplete="new-password"
                validation='email'
                type="email"
                name="username"
                :placeholder="$gettext('username')"
                error-behavior='blur'
                style="margin-bottom: 5px;"
            />
            <FormulateInput
                class="landingPageInput"
                autocomplete="new-password"
                validation='required'
                type="password"
                name="password"
                error-behavior='blur'
                :placeholder="$gettext('password')"
            />
          </div>
          <div class="logo_spacer_bottom"></div>
          <div class="form-group">
            <button type='submit' :disabled='hasErrors' name='login' class="landingPageButton btn btn-info" :value="$gettext('Login')" ><i class="fas fa-sign-in-alt fas-left"></i><translate>LOGIN</translate></button>
            <router-link v-if="registration_enabled == 'yes'" exact :to="{ name: 'register' }">
              <a class="landingPageButton btn btn-lg btn-primary" :value="$gettext('REGISTER')"><i class="fas fa-user fas-left"></i><translate>REGISTER</translate></a>
            </router-link>
            <span v-else class="landingPageButton btn btn-lg btn-primary" style="cursor: not-allowed!important;"><i class="fas fa-user fas-left"></i><translate>REGISTER</translate></span>
            <span v-if="errors.root && product_mode != 'pro'" class='loginSubBtn w-50 mt-2 btn-small'>
              <br /><br />
              <translate>Having issues logging in?</translate><br /><a class="color03" href="https://t.me/farmgod_io" target="_blank"><b><translate>Join our Telegram.</translate></b></a>
            </span>
            <br/>
            <router-link class='loginSubBtn w-50 mt-2 btn btn-outline-secondary btn-small' exact :to="{ name: 'forgot_password' }"><translate>Forgot password?</translate></router-link>
          </div>
        </div>
      </FormulateForm>

    </div>

    <FarmgodFooter>
      <LoggedOutFooter />
    </FarmgodFooter>

    <LanguageSwitcher style="margin: auto; right: 0; left: 0;margin-top: 30px;"/>

  </div>

</template>

<script>
import { mapState, mapGetters } from 'vuex'

import { AUTH__REQUEST_ACCESS_TOKEN, OTP__REQUEST_CONFIGURED_MODE } from '@/store/actions.type'
import FarmgodHeader from '@/components/layout/TheHeader'
import Logo from '@/components/layout/Logo'
import LanguageSwitcher from '@/components/widgets/LanguageSwitcher.vue'
import LoginWidgets from '@/components/widgets/LoginWidgets'
import FarmgodFooter from '@/components/layout/TheFooter'
import LoggedOutFooter from '@/components/layout/Footers/LoggedOut'

import { PRODUCT_MODE, REGISTRATION_ENABLED } from '@/common/config'

export default {
  name: 'FarmgodLogin',
  components: { FarmgodHeader, Logo, FarmgodFooter, LoggedOutFooter, LanguageSwitcher, LoginWidgets },
  data () {
    return {
      product_mode: PRODUCT_MODE,
      registration_enabled: REGISTRATION_ENABLED,
      hasErrors: null,
      values: {
        username: '',
        password: ''
      },
      errors: {}
    }
  },
  computed: {
    ...mapGetters(['otpConfiguredMode']),
    ...mapState({
      otpStatus: state => state.auth.twofaStatus
    })
  },
  methods: {
    isMobile () {
      if (/Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    handleErrors (err) {
      if (!err.response) {
        this.errors = { root: 'There was a server error, please check your connection' }
        return
      }

      const status = parseInt(err.response.status)

      if (status === 400) {
        this.errors = { root: err.response.data.detail }
      } else if (status === 422) {
        const errorsObject = { username: [], password: [] }
        err.response.data.detail
          .map((err) => {
            errorsObject[err.loc[1]].push(err.msg)
          })
        this.errors = errorsObject
      }
    },
    login () {
      this.$store
        .dispatch(AUTH__REQUEST_ACCESS_TOKEN, this.values).then(() => {
          console.log('otpStatus', this.otpStatus)
          if (this.otpStatus === '2FA_SETUP_PENDING') {
            this.$router.push({ name: '2fa_setup__choose_method', query: { email: this.values.username } })
          } else if (this.otpStatus === '2FA_OTP_REQUIRED') {
            this.$store
              .dispatch(OTP__REQUEST_CONFIGURED_MODE).then((result) => {
                if (this.otpConfiguredMode === 'email') {
                  this.$router.push({ name: 'otp__email' })
                } else {
                  this.$router.push({ name: 'otp__app' })
                }
              })
          } else {
            this.$router.push({ name: 'overview' })
          }
        }).catch((err) => {
          this.handleErrors(err)
        })
    }
  },
  created () {
    console.log('PRODUCT_MODE ...........:', this.registration_enabled)
    console.log('REGISTRATION_ENABLED ...:', this.registration_enabled)
  }
}
</script>

<style>
  @import '../assets/style/sessions.scss';
</style>
